

.bg-lowebar {
  height: 115px;
  width: 100%;
  position: absolute;
  left: 476px;
  right: 0;
  bottom: 0;
  background-color: white;
}

.bg-line {
  height: 27px;
  width: 100%;
  z-index: 2;
  position: absolute;
  bottom: 0;
  background-color: var(--ciDarkGreen);
}

.qr-code-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
  /* width: 200px; */
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0 16px;
  text-align: center;
}

.qr-title {
  color: var(--ciDarkGreen);
  padding: 16px 16px 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}