html,
body {
  font-size: 16px;
  font-family: "db_helvethaica_x_custom", "thongterm", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  background: transparent;
  color: white;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --ciOrange: #ef5323;
  --ciDarkGreen: #4a6157;
  --ciLightGreen: #81958c;
  --ciLightCream: #fbf7ee;
  --ciWhite: #fdfcfa;
  --ciBlack: #171510;

  --white: #ffffff;
  --black: #000000;

  --grey: #efefef;
  --grey50: #fdfdfd;
  --grey100: #f6f6f6;
  --grey200: #eaeaea;
  --grey300: #dbdbdb;
  --grey400: #cbcbcb;
  --grey500: #aaaaaa;
  --grey600: #888888;
  --grey700: #646464;
  --grey800: #484848;
  --grey900: #2e2e2e;
  --grey1000: #101010;

  --optional0: #f7f2e9;
  --optional1: #f3efe6;
  --optional2: #f1ece1;
  --optional3: #e2ddd3;
  --optional4: #958e80;
  --optional5: #7a7366;
  --optional6: #554e40;
}

p,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  color: #f75600;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:hover,
  &:focus {
    color: var(--ciOrange);
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
  }
}

small {
  font-size: 80%;
}

button {
  font-family: inherit;
  font-size: 100%;
  background: transparent;
  text-transform: none;
  cursor: pointer;
}

/**
* 1. Correct the inheritance and scaling of font size in all browsers.
* 2. Correct the odd em font sizing in all browsers.
*/
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
* Correct the inability to style clickable types in iOS and Safari.
*/
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
* Remove the border on images inside links in IE 10.
*/

img {
  border-style: none;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  color: var(--ciDarkGreen)
}

h2 {
  font-size: 21px;
  font-weight: 700;
  color: var(--ciDarkGreen)
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: var(--ciDarkGreen)
}

h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--ciDarkGreen)
}

h5 {
  font-size: 12px;
  font-weight: 400;
  color: var(--ciDarkGreen)
}


.page-container {
  height: 1080px;
  width: 1920px;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: transparent;

  /* Hint that some page is clickable */
  cursor: pointer;

  /* Disable text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


@font-face {
  font-family: 'sukhumvitset';
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_text-webfont.eot');
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_text-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_text-webfont.woff2') format('woff2'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_text-webfont.woff') format('woff'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_text-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sukhumvitset';
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_semibold-webfont.eot');
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_semibold-webfont.woff2') format('woff2'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_semibold-webfont.woff') format('woff'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_semibold-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  size-adjust: 100%;
}

@font-face {
  font-family: 'sukhumvitset';
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_bold-webfont.eot');
  src: url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_bold-webfont.woff2') format('woff2'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_bold-webfont.woff') format('woff'),
       url('https://bkkelection65.st-th-1.byteark.com/assets/fonts/sukhumvit/sukhumvit-set_bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxliit-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxliit-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxliit-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxliit-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxliit-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicax-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicax-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicax-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicax-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicax-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxit-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxit-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxit-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxit-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxit-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmed-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmed-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmed-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmed-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmed-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  size-adjust: 100%;
}

@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmedit-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmedit-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmedit-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmedit-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxmedit-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbd-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbd-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbd-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbd-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbd-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'db_helvethaica_x_custom';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbdit-webfont.eot');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbdit-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbdit-webfont.woff2') format('woff2'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbdit-webfont.woff') format('woff'),
       url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/dbhelvethaicaxbdit-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}



/* Avenir Fonts */

@font-face {
  font-family: 'Avenir LT Std';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Black.otf');
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Black.woff') format('woff2'),
      url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Black.woff2') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Roman.woff2') format('woff2'),
      url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Light.woff2') format('woff2'),
      url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir LT Std';
  src: url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Book.woff2') format('woff2'),
      url('https://st-th-1.byteark.com/thaipbs-redesign-2021/assets/fonts/avernir/AvenirLTStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}